import React from 'react';
import { useState, useEffect, useContext } from "react";
import { LangContext } from '../context/LangContext';

import BannerHome from '../assets/images/bannerhome.jpg';

export const BannerHomepage = () => {
    const [bahasa,setBahasa] = useState('id')
    const [sapaan,setSapaan] = useState('')
    const { lang } =  useContext(LangContext);
    
    useEffect(() => {
        var today = new Date();
        var curHr = today.getHours();
        var greet;
        if (curHr < 12) {
            greet = (lang=='id' ? 'Hai, Selamat Pagi' : 'Hello, Good Morning');
        } else if (curHr < 14) {
            greet = (lang=='id' ? 'Hai, Selamat Siang' : 'Hello, Good Afternoon');
        } else if (curHr < 18) {
            greet = (lang=='id' ? 'Hai, Selamat Sore' : 'Hello, Good Afternoon');
        } else {
            greet = (lang=='id' ? 'Hai, Selamat Malam' : 'Hello, Good Night');
        }
        setSapaan(greet)
    },[lang]);

    return(
        <div className="banner" style={{backgroundImage: "url(" + BannerHome + ")"}}>
            <div className="banner__inner">
                <h2>{sapaan}<br/>
                {lang=='id' ? 'Ada yang bisa kami bantu ?' : 'Can we help you ?'}
                </h2>
            </div>
        </div>
    )
}

export const BannerDetail = (props) => {
    return(
        <div className="banner" style={{backgroundImage: "url(" + props.bannerImage + ")"}}>
            <div className="banner__inner center-text">
                <h2>{props.text}</h2>
            </div>
        </div>
    )
}