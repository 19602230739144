import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import './assets/scss/app.scss';
import Footer from './components/Footer'; 
import Navigation from './components/Navigation';

import Home from './page/homepage';
import Detail from './page/Detail';
// import Task from './page/Task';
// import Account from './page/Account';
// import Payment from './page/Payment';
// import Information from './page/Information';

import { LangProvider } from './context/LangContext';

export default function App() {
  return (
    <LangProvider>
    <div className="App">
      <Navigation></Navigation>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/detail/:id/:title" element={<Detail />} />
        {/* <Route path="/task" element={<Task />} />
        <Route path="/account" element={<Account />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/information" element={<Information />} /> */}
      </Routes>
      <Footer></Footer>
    </div>
    </LangProvider>
  );
}

