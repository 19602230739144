import React, { useState, useContext, useEffect } from 'react';
import Logo from '../assets/images/iconlogo.png';
import { LangContext } from '../context/LangContext';
import IdFlag from '../assets/images/id.png';
import EnFlag from '../assets/images/eng.png';
import { Link } from "react-router-dom";


function Navigation() {

    // const [langValue, setLangValue] = useState('');

    const { lang, setLang, detail, setDetail } =  useContext(LangContext);
    const [ modal, setModal ] = useState(false);

    useEffect(() => {
        const langsData = localStorage.getItem('lang');
        console.log(langsData);
        setLang(langsData);
    }, []);
    
    const saveGroup = (event) => {
        setLang(event.target.value);
    }

    const changeLang = () => {
       setModal(true)
    }

    const selectEn = () => {
        setLang('eng')
        localStorage.setItem('lang', 'eng');
        setModal(false)
    }

    const selectId = () => {
        setLang('id')
        localStorage.setItem('lang', 'id');
        setModal(false)
    }
      

    return ( 
        <div className="navigation">
            <div className="navigation__inner">
                {detail}
                {detail ? 
                <Link onClick={() => setDetail(false)} to='/' className="backBtn">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.6665 20L8.33317 20M8.33317 20L19.9998 31.6667M8.33317 20L19.9998 8.33332" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </Link>
                :
                null
                }
                <img src={Logo}></img>
                <span className="logo_sparator"></span>
                <h4 className="logoText">Help Centre</h4>


                <div className="lang">
                    <div className="flagContainer" onClick={() => changeLang()}>
                        <img src={lang === 'eng' ? EnFlag : IdFlag}></img>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 10.4697C16.8232 10.7626 16.8232 11.2374 16.5303 11.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L7.46967 11.5303C7.17678 11.2374 7.17678 10.7626 7.46967 10.4697C7.76256 10.1768 8.23744 10.1768 8.53033 10.4697L12 13.9393L15.4697 10.4697C15.7626 10.1768 16.2374 10.1768 16.5303 10.4697Z" fill="black"/>
                        </svg>
                    </div>
                    { modal ?  
                    <div className="boxPopup">
                        <div className="boxPopup__Item" onClick={() => selectId()}>
                            <img src={IdFlag}></img>
                            <p>Indonesia</p>
                        </div>
                        <div className="boxPopup__Item" onClick={() => selectEn()}>
                            <img src={EnFlag}></img>
                            <p>English</p>
                        </div>
                    </div>
                    :
                    null
                    }

                    {/* <select value={lang} onChange={saveGroup} >
                        <option selected value="eng">EN</option>
                        <option value="id">ID</option>
                    </select> */}
                </div>

            </div>
        </div>
     );
}

export default Navigation;