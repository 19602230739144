import React, {useState, useContext, useEffect} from 'react';
import { Link } from "react-router-dom";

import Caret from '../assets/images/Caret.png';
import { LangContext } from '../context/LangContext';

import { BASE_URL_API } from '../helper/Url';

import { LangProvider } from '../context/LangContext';

export const CardBox = (props) => {

    const { setDetail, isLoading } =  useContext(LangContext);
    if (isLoading) {
        return(
            <h4>Loading</h4>
        )
    }
    return(
        <div className="card_box">
            <div className="card_box__inner">
                <Link onClick={() => setDetail(true)} params={props.params} to={props.urlLink} className="clickarea"></Link>
                <img src={props.icon}></img>
                <h4>{props.text}</h4>
            </div>
        </div>
    )
}

export const Accordion = (props) => {
    const [isActive, setIsActive] = useState(false);
    const [cardId, setCardId] = useState(props.cardId);
    const [response, setResponse] = useState('');


    const { lang } =  useContext(LangContext);

    useEffect(() => {
        setCardId(props.cardId)
    }, [lang])

    const clickFunction = () => {
        setIsActive(!isActive)
        if(!isActive) {
            fetch(BASE_URL_API+'/view/'+cardId, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                // body: JSON.stringify({ title: 'React POST Request Example' })
            })
            .then(response => response.json()) 
            .then(data => {
                console.log(data)
                // setCategory(data.data)
                // this.setState({ data.data });
            });
        }
    }


    ////////DISLIKE////////
    const dislike = () => {
        fetch(BASE_URL_API+'/like/'+cardId+'/tidak_suka', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({ title: 'React POST Request Example' })
        })
        .then(response => response.json()) 
        .then(data => {
            console.log(data);
            setResponse('no');
            // setCategory(data.data)
            // this.setState({ data.data });
        });
    }

    ////////DISLIKE////////
    const like = () => {
        fetch(BASE_URL_API+'/like/'+cardId+'/suka', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({ title: 'React POST Request Example' })
        })
        .then(response => response.json()) 
        .then(data => {
            console.log(data);
            setResponse('yes');
            // setCategory(data.data)
            // this.setState({ data.data });
        });
    }

    return(
        <div className={isActive ? 'accordion active' : 'accordion'}>
            <div className="accordion_header" onClick={() => clickFunction()} >
                <div className="accordion_header_title" >{props.title}</div>
                <div className={isActive ? 'caret active' : 'caret'}>
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.616117 0.449491C1.10427 -0.0386648 1.89573 -0.0386648 2.38388 0.449491L9.05055 7.11616C9.53871 7.60431 9.53871 8.39577 9.05055 8.88392L2.38388 15.5506C1.89573 16.0387 1.10427 16.0387 0.616117 15.5506C0.127961 15.0624 0.127961 14.271 0.616117 13.7828L6.3989 8.00004L0.616117 2.21726C0.127961 1.7291 0.127961 0.937646 0.616117 0.449491Z" fill="black"/>
                    </svg>
                </div>
            </div>
            <div className="accordion_content">
                    <div className="accordion_content"
                        dangerouslySetInnerHTML={{__html: props.content}}
                    />

                    {/* {renderHTML({props.content})} */}

                <div className="likeBtn">
                    <h4>{lang === 'eng' ? 'Did this answer help you?' : 'Apakah jawaban ini membantu'}</h4>
                    <div className="response">
                        <div  onClick={() => like()} className={response === 'yes' ? 'responseItem active' : 'responseItem'}>
                            <h4>{lang === 'eng' ? 'YES' : 'YA'}</h4>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 22V11M2 13V20C2 21.1046 2.89543 22 4 22H17.4262C18.907 22 20.1662 20.9197 20.3914 19.4562L21.4683 12.4562C21.7479 10.6389 20.3418 9 18.5032 9H15C14.4477 9 14 8.55228 14 8V4.46584C14 3.10399 12.896 2 11.5342 2C11.2093 2 10.915 2.1913 10.7831 2.48812L7.26394 10.4061C7.10344 10.7673 6.74532 11 6.35013 11H4C2.89543 11 2 11.8954 2 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div onClick={() => dislike()} className={response === 'no' ? 'responseItem activeRed' : 'responseItem'}>
                            <h4>{lang === 'eng' ? 'NO' : 'TIDAK'}</h4>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 22V11M2 13V20C2 21.1046 2.89543 22 4 22H17.4262C18.907 22 20.1662 20.9197 20.3914 19.4562L21.4683 12.4562C21.7479 10.6389 20.3418 9 18.5032 9H15C14.4477 9 14 8.55228 14 8V4.46584C14 3.10399 12.896 2 11.5342 2C11.2093 2 10.915 2.1913 10.7831 2.48812L7.26394 10.4061C7.10344 10.7673 6.74532 11 6.35013 11H4C2.89543 11 2 11.8954 2 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}