import React ,{useState, useEffect}from 'react';

export const LangContext = React.createContext()

export const LangProvider = ({ children }) => {
    const [lang, setLang] = useState('');
    const [detail, setDetail] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {
    }, []);

    
    return(
        <LangContext.Provider value={{lang, setLang, detail, setDetail, isLoading, setisLoading}}>
            {children}
        </LangContext.Provider>
    )
}