import { useState, useEffect, useContext } from "react";

import Navigation from '../components/Navigation';
import { BannerHomepage, BannerDetail } from '../components/Banner';
import { CardBox } from '../components/Card';
import { Accordion } from '../components/Card';
import { useParams } from 'react-router';
import { BASE_URL_API, BASE_URL_CDN } from '../helper/Url';
import { LangContext } from '../context/LangContext';
import LoadinGif from '../assets/images/loading.gif';

//import Banner Bg
import BannerAccount from '../assets/images/BannerAkun.jpg';

function Detail() {

    const { lang, setisLoading, isLoading, setDetail } =  useContext(LangContext);
    const { id, title } = useParams();
    const [detailData, setDetailData] = useState([]);
    const [datatitle, setDataTitle] = useState([]);
  
    useEffect(() => {
        console.log(lang)
        setisLoading(true)
        ///
        document.title = title + " | Help Center IOWork"
        fetch(BASE_URL_API+'/category_detail/'+id, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({ title: 'React POST Request Example' })
        })
        .then(response => response.json()) 
        .then(data => {
            console.log(data.data)
            setDetailData(data.data)
            setisLoading(false)
            setDetail(true)
            // setTitle(data.data.title_id)
            // this.setState({ data.data });
        });

    },[lang]);

    return (
        <div className="App">
            <BannerDetail text={title} bannerImage={BannerAccount}></BannerDetail>
            {/* {id}{title} */}
            <div className="content_section">
                <div className="content_section__inner">
                {isLoading ? 
                    <div className="loading">
                        <img src={LoadinGif}></img>
                    </div>
                    :
                    <div className="content_col">
                        {detailData.map((item,index) => {
                            return(
                                <Accordion 
                                cardId={item.id}
                                key={index}
                                title={lang === 'eng' ? item.title_en : item.title_id}
                                content={lang === 'eng' ? item.konten_en : item.konten_id}></Accordion>
                            )
                        })}

                    </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Detail;
