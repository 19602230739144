
//----- PRODUCTION --------------------------------------------------
export const BASE_URL = 'https://help.iowork.id';
export const BASE_URL_API = 'https://office.iowork.id/faq/api';
// export const BASE_URL_CDN = 'https://iowork.sgp1.digitaloceanspaces.com/image/help/kategori-faq';

//----- DEVELOPMENT --------------------------------------------------
// export const BASE_URL = 'https://help.iowork.id';
// export const BASE_URL_API = 'https://office-iowork.megakreasiteknologi.com/faq/api';
// export const BASE_URL_CDN = 'https://iowork.sgp1.digitaloceanspaces.com/image/help/kategori-faq';


//----- LOCAL --------------------------------------------------
// export const BASE_URL = 'http://localhost:3000';
// export const BASE_URL_API = 'https://office-iowork.megakreasiteknologi.com/api';
// export const BASE_URL_CDN = 'https://iowork.sgp1.digitaloceanspaces.com/image/help/kategori-faq';
