import React from 'react';
import Logo from '../assets/images/iconlogo.png';

function Footer() {
    return ( 
        <div className="footer">
            <div className="footer__inner">
                <span>PT. Mega Kreasi Teknologi, 2022</span>
                <span>|</span>
                <p>Privacy Policy</p>
                <span>|</span>
                <p>Term & Condition</p>
            </div>
        </div>
     );
}

export default Footer;