import { useState, useEffect, useContext } from "react";
import { BannerHomepage } from '../../src/components/Banner';
import { CardBox } from '../../src/components/Card';
import { BASE_URL_API, BASE_URL_CDN } from '../helper/Url';
import { LangContext } from '../context/LangContext';

import LoadinGif from '../assets/images/loading.gif';

//import Banner Bg
import BannerTask from '../assets/images/BannerTask.jpg';
// import BannerPayment from '../assets/images/BannerPayment.jpg';
// import BannerAccount from '../assets/images/BannerAkun.jpg';
// import BannerInfo from '../assets/images/BannerInfo.jpg';

// import IconTask from '../assets/images/icontask.png';
// import IconAccount from '../assets/images/iconaccount.png';
// import IconPayment from '../assets/images/iconpayment.png';
// import IconInfo from '../assets/images/iconinfo.png';

function Homepage() {
  
    const [category,setCategory] = useState([])
    const { lang, setLang, setisLoading, isLoading } =  useContext(LangContext);

    
    useEffect(() => {
        setisLoading(true)
        document.title = "Help Center | IOWork"
        fetch(BASE_URL_API+'/category', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            // body: JSON.stringify({ title: 'React POST Request Example' })
        })
        .then(response => response.json()) 
        .then(data => {
            console.log(data.data)
            setCategory(data.data)
            setisLoading(false)
            // this.setState({ data.data });
        });
        //
        // const langsData = JSON.parse(localStorage.getItem('lang'));
        // setLang(langsData);
    },[]);


    return (
        <div className="App">
            <BannerHomepage text="Task" bannerImage={BannerTask}></BannerHomepage>
            <div className="content_section">
                <div className="content_section__inner">
                    <h3 className="title">{lang === 'eng' ? 'Category' : 'Kategori'}</h3>
                    {isLoading ? 
                    <div className="loading">
                        <img src={LoadinGif}></img>
                    </div>
                    :
                    <div className="content_grid">
                        {category.length > 0 ? 
                            category.map((item, index) => {
                                return (<CardBox key={index} urlLink={ lang === 'eng' ? `detail/`+item.id+`/`+item.nama_en : `detail/`+item.id+`/`+item.nama_id} icon={item.gambar} text={ lang === 'eng' ? item.nama_en : item.nama_id}></CardBox>);
                            })
                        :
                            null
                        }
                    </div>
                    }
                    
                </div>
            </div>
        </div>
    );
}

export default Homepage;
